.hero-img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  aspect-ratio: 612 / 408;
  box-sizing: border-box;
  min-height: 612px;
}

.hero-container {
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 612px;
}

.quote-button {
  background-color: var(--white);
  border: 2px solid var(--blue) !important;
  color: var(--blue);
  transition: 200ms;
}

.quote-button:hover {
  background-color: var(--grey);
}

.hammer-head {
  font-size: 6.5rem;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  color: var(--white);
  text-align: center;
  height: 110px;
  text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.851);
}

/* Ipad Screens */
@media screen and (max-width: 1280px) {
  .hammer-head {
    font-size: 4rem;
    height: 60px;
  }
  .hero-home-services {
    font-size: 3.5rem !important;
    letter-spacing: 9px !important;
    height: 62px !important;
  }
  .free-estimates {
    font-size: 2rem !important;
    line-height: 35px !important;
    height: 58px;
  }
}

/* phone Screens */
@media screen and (max-width: 600px) {
  .hammer-head {
    font-size: 3rem;
    height: 40px;
  }
  .hero-home-services {
    font-size: 2.3rem !important;
    letter-spacing: 9px !important;
    height: 44px !important;
  }
  .free-estimates {
    font-size: 1.2rem !important;
    line-height: 24px !important;
    height: 40px;
  }
  .hammer-head-hero-logo {
    width: 80% !important;
    transform: translateX(-10px);
  }
}

.hero-home-services {
  font-size: 6rem;
  letter-spacing: 14px;
  font-family: "tandelle" !important;
  color: var(--white);
  text-align: center;
  text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.851);
  height: 115px;
}

.free-estimates {
  font-family: "Arial Narrow", Arial, sans-serif;
  color: var(--white);
  font-size: 3rem;
  text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.851);
  line-height: 50px;
  letter-spacing: 3px;
}

.hero-quote {
  color: var(--white);
  font-size: 1rem;
}

.floating-logo {
  position: absolute;
  width: 100%;
  max-width: 600px;
  top: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.hammer-head-hero-logo {
  aspect-ratio: 746 / 275;
  width: 100%;
  height: auto;
}

.home-services {
  background-color: var(--blue);
  color: var(--white);
  padding: 20px 0;
}

.home-services .service {
  text-align: center;
}

.display-services {
  width: 100%;
  padding: 20px;
  display: flex;
}

@media screen and (max-width: 854px) {
  .display-services {
    flex-direction: column !important;
    align-items: center;
  }

  .display-services:nth-child(even) {
    flex-direction: column !important;
  }

  .service-img-container {
    width: 100% !important;
    min-width: 0px !important;
    display: flex;
    justify-content: center;
  }

  .service-image {
    width: 100% !important;
    max-width: 352px;
    min-width: 0px !important;
  }

  .service-description {
    width: 100% !important;
  }

  .service-description .button {
    width: 100% !important;
  }
  .quote-image-container {
    display: none !important;
  }
}

.service-img-container {
  width: 40%;
  min-width: 352px;
}

.service-image {
  aspect-ratio: 4044 / 3372;
  width: 100%;
  height: auto;
  border: var(--border-radius);
  object-fit: cover;
  object-position: center;
}

.service-description {
  width: 60%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
}

.display-services-odd {
  flex-direction: row-reverse;
  background-color: var(--blue);
  color: var(--white);
}

.home-quote-container {
  height: 125px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.quote-image-container {
  width: 100%;
  max-width: 150px;
}

.home-quote-logo {
  aspect-ratio: 1280 / 889;
  width: 100%;
  height: auto;
}

.left-logo {
  transform: scaleX(-1);
}

.home-quote {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-quote h1 {
  height: 100%;
}

.service a {
  text-decoration: none;
}
