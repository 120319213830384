:root {
  --black: #333;
  --grey: #d2d2d2;
  --white: #fff;
  --white-hover: #eceaea;
  --blue: #020062;
  --blue-hover: #020086;
  --yellow: #e9c913;
  --grey: #dedede;
  --font-small: 12pt;
  --font-medium: 14pt;
  --font-large: 18pt;
  --font-xl: 24pt;
  --font-xxl: 30pt;
  --border-radius: 13px;
  --header-height: 60.67px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  max-width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.main-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

#root {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  width: 100%;
}

.button {
  text-align: center;
  text-decoration: none;
  width: 260px;
  padding: 10px 30px;
  font-size: var(--font-medium);
  border: none;
  border-radius: var(--border-radius);
  transition: 200ms;
}

.button:hover {
  cursor: pointer;
}

.button-blue {
  background-color: var(--blue);
  color: var(--white);
}

.button-blue:hover {
  background-color: var(--blue-hover);
}

.button-white {
  background-color: var(--white);
  color: var(--black);
}

.button-white:hover {
  background-color: var(--white-hover);
}

/* Nav Bar */

.nav-bar {
  max-width: 100%;
  z-index: 1;
  background-color: var(--blue) !important;
  color: var(--white) !important;
  position: fixed !important;
  top: 0;
  left: 0;
  border-bottom: 1px solid var(--black);
  transition: transform 0.3s ease-in-out;
}

.nav-bar a {
  color: var(--white) !important;
}

.navbar-logo {
  width: 65px;
  height: auto;
  aspect-ratio: 526 / 206;
}

.navbar-hidden {
  transform: translateY(-100%);
}

.loading {
  background-color: var(--black);
  animation: loading 2s infinite;
}

@keyframes loading {
  0% {
    background-color: var(--black);
  }

  50% {
    background-color: #444;
  }

  100% {
    background-color: var(--black);
  }
}

/* Footer */

footer {
  width: 100%;
  background-color: var(--blue);
  color: var(--white);
  padding: 10px;
  display: flex;
}

@media screen and (max-width: 620px) {
  footer {
    flex-direction: column;
  }

  .footer-left-side {
    max-width: 100% !important;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-right-side {
    width: 100% !important;
  }

  .footer-right-side-top {
    justify-content: center !important;
  }
}

.footer-logo {
  aspect-ratio: 4167 / 1749;
  width: 100%;
}

.footer-left-side {
  width: 40%;
  max-width: 500px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  padding: 20px;
}

.footer-right-side {
  width: 60%;
}

.footer-right-side-top {
  display: flex;
  justify-content: end;
  padding: 20px;
}

.footer-right-side-top input {
  border: none;
  padding: 5px 15px;
}

.footer-right-side-top button {
  border: 1px solid var(--white);
  padding: 10px 20px;
  margin-left: 10px;
  background-color: var(--white);
  transition: 200ms;
}

.footer-right-side-top button:hover {
  background-color: var(--white-hover);
}

.footer-right-side-bottom {
  display: flex;
  justify-content: space-evenly;
}

.footer-right-side-bottom ul {
  list-style-type: none;
}

.footer-right-side-bottom a {
  text-decoration: none;
  color: var(--white);
}

.footer-email {
  overflow-wrap: anywhere;
  hyphens: auto;
}

.underline-animation {
  display: inline-block;
  position: relative;
  color: var(--white);
}

.underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--white);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.red {
  color: red;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
}
